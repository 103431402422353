import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';

import {
    Arrow,
    Layout,
    Observer,
    PhotoContent,
    AnimateTitle,
    ContentfulImage,
} from '../../components';

import styles from './Home.module.css';

const ABOUT_SECTION_ID = 'about';
const SECTIONS_IMAGE_ANIMATION = {
    first: {
        type: 'slide',
        direction: 'left',
    },
    second: {
        type: 'slide',
        direction: 'right',
    },
    third: {
        type: 'slide',
        direction: 'left',
    },
    fourth: {
        type: 'slide',
        direction: 'right',
    },
};

const defaultMetadataQuery = graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
`;

export const Home = ({
    data: {
        contentful: {
            homePageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const { title } = useStaticQuery(defaultMetadataQuery).site.siteMetadata;

    const {
        heroText,
        heroTextAuthor,
        heroImage,
        mobileHeroImage,
        contentBlock1Text,
        contentBlock1Image,
        contentBlock2Text,
        contentBlock2Image,
        contentBlock3Text,
        contentBlock3Image,
        contentBlock4Text,
        contentBlock4Image,
    } = page;

    const mobileSourceElem = (
        <source
            media="(max-width: 768px)"
            srcSet={ mobileHeroImage.url } />
    );

    const seoData = {}; // This way it will use the default values (gatsby-config.js)

    return (
        <Layout
            seoData={ seoData }
            location={ location }>
            <section className={ styles.hero }>
                <h1 className={ styles.heading }>{ title }</h1>
                <div className={ styles.backgroundImage }>
                    <ContentfulImage
                        src={ heroImage.url }
                        source={ mobileSourceElem }
                        alt={ heroImage.description } />
                </div>
                <div className={ styles.overlay } />
                <Observer>
                    { (isVisible) => (
                        <div className={ styles.textContainer }>
                            <AnimateTitle
                                className={ styles.title }
                                startAnimation={ isVisible }>
                                <h2 className={ styles.heroTitle }>{ heroText }</h2>
                            </AnimateTitle>
                            <AnimateTitle
                                className={ styles.author }
                                startAnimation={ isVisible }>
                                <p>{ heroTextAuthor }</p>
                            </AnimateTitle>
                        </div>
                    ) }
                </Observer>
                <AnchorLink
                    offset={ 110 }
                    className={ styles.arrow }
                    href={ `#${ABOUT_SECTION_ID}` }>
                    <Arrow />
                </AnchorLink>
            </section>
            <section id={ ABOUT_SECTION_ID }>
                <PhotoContent
                    className={ styles.aboutFirst }
                    src={ contentBlock1Image.url }
                    alt={ contentBlock1Image.description }
                    imageAnimation={ SECTIONS_IMAGE_ANIMATION.first }>
                    <p>{ contentBlock1Text }</p>
                </PhotoContent>
                <PhotoContent
                    imageAlignment="right"
                    className={ styles.aboutSecond }
                    src={ contentBlock2Image.url }
                    alt={ contentBlock2Image.description }
                    imageAnimation={ SECTIONS_IMAGE_ANIMATION.second }
                    textContentClassName={ styles.textContentAboutSecond }
                    imageContentClassName={ styles.imageContentAboutSecond }>
                    <p>{ contentBlock2Text }</p>
                </PhotoContent>
                <PhotoContent
                    className={ styles.aboutThird }
                    src={ contentBlock3Image.url }
                    alt={ contentBlock3Image.description }
                    imageAnimation={ SECTIONS_IMAGE_ANIMATION.third }
                    textContentClassName={ styles.textContentAboutThird }
                    imageContentClassName={ styles.imageContentAboutThird }>
                    <p>{ contentBlock3Text }</p>
                </PhotoContent>
                <PhotoContent
                    imageAlignment="right"
                    className={ styles.aboutFourth }
                    src={ contentBlock4Image.url }
                    alt={ contentBlock4Image.description }
                    imageAnimation={ SECTIONS_IMAGE_ANIMATION.fourth }>
                    <p>{ contentBlock4Text }</p>
                </PhotoContent>
            </section>
        </Layout>
    );
};

Home.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            homePageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Home;
